<template>
  <v-dialog v-model="show" :fullscreen="fullscreen" >
    <v-card elevation="20" class="text-left">

      <slot></slot>

<!--       <v-card-actions>
        <v-btn color="primary" flat @click.stop="show=false">Close</v-btn>
      </v-card-actions> -->

    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
     value: Boolean,
     fullscreen: { type: Boolean, default: false }
  },
  computed: {
    show: {
      get () { return this.value },
      set (value) { this.$emit('input', value) },
    }
  }
}
</script>