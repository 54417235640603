<template>
  <ConfirmationDialog v-model="show">
    <v-card-title>
      Tafadhali Nipigie
    </v-card-title>
    <v-card-subtitle>
      Ultimate Finance itakupigia simu haraka iwezekanavyo.
    </v-card-subtitle>
    <v-card-actions>
      <v-btn color="primary" dense @click="show=false">Funga</v-btn>
    </v-card-actions>
  </ConfirmationDialog>
</template>


<script>
import ConfirmationDialog from "@/components/ConfirmationDialog";

export default {
  components: { ConfirmationDialog },
  props: {
     value: Boolean,
     customer: Object,
  },
  data() {
    return {
      show: undefined,
    };
  },
  watch: { 
    value: {
      immediate: true,
      handler(to,from) { this.show = to; },
    },
    show: {
      immediate: true,
      handler(to,from) {
        this.$emit('input', to);
        if (to) { this.makeApiCall(); }
      },
    },
  },
  methods: {
    async makeApiCall() {
      console.log(this.customer);
      console.log('call me');
    },
  },
}
</script>