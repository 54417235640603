export default {
  data: () => ({
    sources: ['Village Meeting', 'Government office (DVO, LC etc)', 'Current VA referral', 'Other'],
    professions: ['Poultry farmer', 'Boda Driver', 'Crop farmer','Teacher','Salaried worker','Student','Unemployed','Personal business (duuka, shop, etc)','Vet'],
    vehicles: ['None', 'Bicycle', 'Boda boda'],
  }),

  computed: {
    subcounties() { return [...new Set(this.$store.getters.customers.map( e => e.uzimaSubcounty ) )].filter( e => e).sort(); },
    parishes() { return [...new Set(this.$store.getters.customers.map( e => [e.savings_group_name, (e.savingsGroup || {}).name] ).flat() )].filter( e => e).sort(); },
  },
};
