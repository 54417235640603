<template>
  <v-card class="text-left" >

   <v-list-item three-line>
      <v-list-item-content>
        <v-list-item-title class="text-h6 mb-1 font-weight-bold">
         {{loan.product}}
        </v-list-item-title>
      </v-list-item-content>

      <v-list-item-avatar
        tile
        size="40"
      >
      <img
        alt="user"
        src="@/assets/ultimate-finance-logo.jpeg"
        >
      </v-list-item-avatar>
    </v-list-item>

    <v-list>

      <v-list-item>
        <v-list-item-content><v-list-item-title class="text-subtitle-1 mb-1">Salio la Mkopo</v-list-item-title></v-list-item-content>
        <v-list-item-action>
          <v-list-item-title class="text-subtitle-1 mb-1">
            <Currency showCurrency :value="loan.balance" />
          </v-list-item-title>
        </v-list-item-action>
      </v-list-item>

      <v-list-item v-if="loan.due_date">
        <v-list-item-content><v-list-item-title class="text-subtitle-1 mb-1">Tarehe ya kukamilisha</v-list-item-title></v-list-item-content>
        <v-list-item-action>
          <v-list-item-title><Time :time="loan.due_date" format="d MMM" /></v-list-item-title>
        </v-list-item-action>
      </v-list-item>

      <v-list-item>
        <v-list-item-content><v-list-item-title class="text-subtitle-1 mb-1">Ulipaji mdogo</v-list-item-title></v-list-item-content>
        <v-list-item-action>
          <v-list-item-title class="text-subtitle-1 mb-1"><Currency showCurrency :value="loan.minumum_payment" /></v-list-item-title>
        </v-list-item-action>
      </v-list-item>

      <v-list-item>
        <v-list-item-content><v-list-item-title class="text-subtitle-1 mb-1">Upeo wa Marejesho</v-list-item-title></v-list-item-content>
        <v-list-item-action>
          <v-list-item-title><Currency showCurrency :value="loan.maximum_payment" /></v-list-item-title>
        </v-list-item-action>
      </v-list-item>

      <v-list-item>
        <v-list-item-content><v-list-item-title class="text-subtitle-1 mb-1">Pesa Zinazopatikana</v-list-item-title></v-list-item-content>
        <v-list-item-action>
          <v-list-item-title class="text-subtitle-1 mb-1"><Currency showCurrency :value="loan.available" /></v-list-item-title>
        </v-list-item-action>
      </v-list-item>
    </v-list>

    <v-card-actions class="d-flex flex-wrap justify-space-between align-center">

      <v-btn small color="primary" class="my-2" @click="$emit('rollover', loan)" v-if="loan.rollover_allowed">
        Lipa riba pekee
      </v-btn>

      <v-btn small color="primary" class="my-2" @click="$emit('settle', loan)">
        Maliza Mkopo
      </v-btn>

      <v-btn small color="primary" class="my-2" @click="$emit('repay', loan)">
        Rejesha Mkopo
      </v-btn>
    </v-card-actions>

  </v-card>
</template>

<script>
  import Currency from "@/components/Currency.vue";
  import Time from "@/components/Time";

  export default {

    components: { Currency, Time },

    props: {
      loan: Object,
    },

    data() {
      return {
        show: false,
        showRolloverModal: false,
      };
    },
  };
</script>

<style>
  .v-card--reveal {
    bottom: 0;
    opacity: 1 !important;
    position: absolute;
    width: 100%;
  }
</style>
