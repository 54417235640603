<template>
  <span>
    <v-img v-if="logo==='apex_tz'"      :max-height="maxHeight" :max-width="maxWidth" contain src="@/assets/apex_tz.png" />
    <v-img v-if="logo==='hr_co'"        :max-height="maxHeight" :max-width="maxWidth" contain src="@/assets/hr_co.gif" />
    <v-img v-if="logo==='sasa'"         :max-height="maxHeight" :max-width="maxWidth" contain src="@/assets/logo.png" />
    <v-img v-if="logo==='uzima_ug'"     :max-height="maxHeight" :max-width="maxWidth" contain src="@/assets/uzima_ug.png" />
    <v-img v-if="logo==='winwin_zw'"    :max-height="maxHeight" :max-width="maxWidth" contain src="@/assets/winwin_zw.jpg" />
    <v-img v-if="logo === 'ultimate_tz'" :max-height="maxHeight" :max-width="maxWidth" contain src="@/assets/ultimate-finance-logo.jpeg"/>
  </span>
</template>

<script>
  export default {
    props: {
      maxHeight: { type: Number, default: 40 },
      maxWidth:  { type: Number, default: 40 },
    },
    computed: {
      logo() {
        const partnerCode = this.$store.getters.settings.partnerCode;
        let l = 'sasa';
        if (['apex_tz', 'hr_co', 'uzima_ug', 'winwin_zw','ultimate_tz'].includes(partnerCode)) { l = partnerCode; }
        return l;
      }
    }
  };
</script>
