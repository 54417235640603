<template>
  <ConfirmationDialog v-model="show">
    <v-card-title>
      Omba Mkopo
    </v-card-title>
    <v-card-subtitle>
      Ultimate Finance itakupigia simu haraka iwezekanavyo ili kujadili mahitaji yako ya kifedha.
    </v-card-subtitle>
    <v-card-text>
      <v-form v-model="valid">
        <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            label="Jina la kwanza"
            v-model="firstName"
            :rules="[rules.inahitajika]"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            label="Jina la familia"
            v-model="lastName"
            :rules="[rules.inahitajika]"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
         <v-radio-group
            v-model="employed"
            row
            mandatory
            :label="'Je, umeajiriwa?'"
            :rules="[rules.inahitajika]"
          >
            <v-radio
              label="Ndio"
              value="Ndio"
            ></v-radio>
            <v-radio
              label="La"
              value="La"
            ></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12" sm="6" v-if="!disableEmployer">
          <v-text-field
            label="Jina la mwajiri"
            v-model="employerName"
            :disabled="disableEmployer"
            :rules="[rules.inahitajika]"
          ></v-text-field>
        </v-col>
         <v-col cols="12" sm="6" v-if="disableEmployer">
          <v-text-field
            label="Jina la mwajiri"
            v-model="employerName"
            :disabled="disableEmployer"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field  label="Mshahara Halisi"
           v-model.number="netSalary"
           type="number"
           required prefix="TSh" 
           :rules="[rules.inahitajika, rules.numberRules]" />
        </v-col>
      </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-row>
          <v-col cols="12">
            <div class="d-flex justify-space-between flex-wrap">
              <div class="text-left d-flex mr-1 mb-3">
                <v-btn
                  color="primary"
                  dense
                  @click="show=false"
                  class="d-sm-inline"
                  >{{ $t("Funga") }}</v-btn
                >
                <v-btn
                  @click="requestLoan"
                  color="primary"
                  :disabled="!valid"
                  class="mx-2 d-sm-inline-block"
                  >{{ $t("Wasilisha") }}</v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
    </v-card-actions>
  </ConfirmationDialog>
</template>


<script>
import ConfirmationDialog from "@/components/ConfirmationDialog";

export default {
  components: { ConfirmationDialog },
  props: {
     value: Boolean,
     customer: Object,
  },
  emits:['requestLoan'],
  data() {
    return {
      show: undefined,
      valid: false,
      employed: 'No',
      netSalary:0,
      employerName: '',
      lastName:'',
      firstName:'',
      disableEmployer: false,
      rules: {
        inahitajika: (value) => !!value || "inahitajika",
        numberRules: [
          (v) => Number.isInteger(Number(v)) || "Nambari pekee",
          (v) => Number(v) >= 0 || "Nambari chanya tafadhali",
          (v) => !/\D/.test(v) || "Nambari tafadhali",
        ],
      }
    };
  },
  watch: { 
    value: {
      immediate: true,
      handler(to,from) { this.show = to; },
    },
    show: {
      immediate: true,
      handler(to,from) {
        this.$emit('input', to);
        if (to) { this.makeApiCall(); }
      },
    },
    employed:{
      immediate: true,
      handler(to, from){
        if(to == 'La'){
          this.disableEmployer = true;
        }else {
          this.disableEmployer = false;
        }
      }
    }
  },
  methods: {
    async makeApiCall() {
      console.log(this.customer);
      console.log('makeApiCallRequestLoan');
    },
    requestLoan(){
      this.show = false;
      const payload = {
        employed:this.employed,
        netSalary:this.netSalary,
        employerName: this.employerName,
        lastName:this.lastName,
        firstName:this.firstName
      }
      
      this.$emit('requestLoan', payload);
    }
  },
}
</script>