<template>
  <div class="text-center">
    <v-snackbar
      centered
      top
      v-model="snackBar.show"
      :timeout="snackBar.timeout"
      :color="snackBar.color"
    >
      {{ snackBar.message }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="handleClose">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  props: {
    timeout: {
      default: 4000,
      type: Number,
    },
  },
  computed: {
    snackBar() {
      return this.$store.state.snackBar;
    },
  },

  methods: {
    handleClose() {
      this.$store.dispatch("setSnackBar", {
        show: false,
        color: "success",
        timeout: 1,
        message: "",
      });
    },
  },
};
</script>

<style></style>
