import '@mdi/font/css/materialdesignicons.css'

import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

let colour_scheme = {
  primary: '#3566AF',
  accent: '#e91e63',
  secondary: '#30b1dc',
  success: '#4CAF50',
  info: '#3664B0',  // complements primary
  warning: '#FB8C00',
  error: '#FF5252'
}

if (window.location.host.startsWith("app.e-mazao.co.tz")) {
  colour_scheme = {
     primary: '#139645',
     accent: '#e91e63',
     secondary: '#30b1dc',
     success: '#4CAF50',
     info: '#3664B0',  // complements primary
     warning: '#FB8C00',
     error: '#FF5252'
  }
}

if (window.location.host.startsWith("https://ultimate.sasa.solutions/#/")) {
  colour_scheme = {
     primary: '#0A4EA2',
     secondary: '#2161AD',
     accent: '#EB3E24',
     sasaAppBarBackground: '#f5f5f5',
     success: '#4CAF50',
     info: '#3664B0',  // complements primary
     warning: '#FB8C00',
     error: '#FF5252'
  }
}


// https://lobotuerto.com/vuetify-color-theme-builder/
// https://www.canva.com/learn/100-color-combinations/

export default new Vuetify({
  theme: {
      options: {
        customProperties: true,
      },
    themes: {
      light: colour_scheme,
    },
  },
  icons: {
    iconfont: 'mdi',
  },
});
