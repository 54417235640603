<template>
  <v-card>

    <v-card-title>
      Uzima
      <v-spacer />
      <v-btn color="primary" small dense @click="edit">{{$t('edit')}}</v-btn>
    </v-card-title>

    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <Row :title="$t('Village Ambassador?')" :text="(customer.isAnUzimaVillageAmbassador ? 'Is a VA' : 'No')" v-if="customer.isAnUzimaVillageAmbassador" />
            <Row :title="$t('Agent?')" :text="(customer.isAnUzimaAgent ? 'Is an agent' : 'No')" v-if="customer.isAnUzimaAgent" />
            <Row :title="$t('Subcounty')" :text="customer.uzimaSubcounty" v-if="customer.uzimaSubcounty" />
            <Row :title="$t('Parish')" :text="parish" v-if="parish" />
            <Row :title="$t('Primary Income')" :text="customer.primaryIncome" v-if="customer.primaryIncome" />
            <Row :title="$t('Transport?')" :text="customer.transport" v-if="customer.transport" />
            <Row :title="$t('Lead Source')" :text="customer.leadSource" v-if="customer.leadSource" />
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>

  </v-card>
</template>


<script>

  import Row from '@/components/Row';

  export default {

    components: {
      Row,
    },

    props: ['customer'],

    computed: {
      customerUuid() { return this.$route.query.uuid; },
      parish() { return this.customer.savings_group_name || (this.customer.savingsGroup || {}).name; },
    },

    methods: {
      edit() { this.$router.push({name: 'EditUzimaUgDetail', query: { uuid: this.customerUuid }}); },
    },

  }
</script>


<i18n>
{
  "en": {
    "noData": "No demographic data defined."
  },
  "es": {
    "noData": "No se han definido datos demográficos."
  },
  "sw": {
    "noData": "Hakuna data ya idadi ya watu iliyofafanuliwa."
  }
}
</i18n>
