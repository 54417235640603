<template>
  <ConfirmationDialog v-model="show">
    <v-card-title>
      Malizia Mkopo
    </v-card-title>
    <v-card-subtitle>
      Tafadhali thibitisha kuwa ungependa kulipa mkopo huu. Kuendelea kutaanzisha ombi la uidhinishaji malipo wa <Currency showCurrency :value="balance" /> kwa simu yako.
    </v-card-subtitle>
    <v-card-actions>
      <v-btn color="secondary" dense @click="show=false">Ghairi</v-btn>
      <v-spacer />
      <v-btn color="primary" dense @click="submit">Malizia Mkopo</v-btn>
    </v-card-actions>
  </ConfirmationDialog>
</template>


<script>
  import Currency from "@/components/Currency.vue";
  import ConfirmationDialog from "@/components/ConfirmationDialog";

  export default {
    components: { ConfirmationDialog, Currency },
    props: {
       value: Boolean,
       loan: Object,
    },
    data() {
      return {
        show: undefined,
      };
    },
    computed: {
      balance() { return ((this.loan || {}).balance || 0); },
    },
    watch: { 
      value: {
        immediate: true,
        handler(to,from) { this.show = to; },
      },
      show: {
        immediate: true,
        handler(to,from) {
          this.$emit('input', to);
        },
      },
    },
    methods: {
      submit() {
        this.show = false;
        this.$emit('alert', "Mchakato wa suluhu wa mkopo umeanzishwa. Baada ya muda mfupi utapokea dirisha ibukizi kwenye simu yako ya mkononi ili kuidhinisha malipo.");
        console.log('save');
      },
    },
  }
</script>