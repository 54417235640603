<template>
  <v-app-bar dense app color="#ffffff">

    <v-img :max-height="40" :max-width="150" contain src="@/assets/ultimate-finance-header-logo.png" />

    <v-spacer></v-spacer>

    <a href="tel:0677030962" class="text-h6" style="color: #0850a0; text-decoration: none;">067 703 0962</a>

  </v-app-bar>

</template>

<script>
import Logo from '@/components/HeaderBarLogo';

export default {
  components: { Logo },
  computed: {
    live() { return this.$store.getters.authenticated; },
  },
  methods: {
    logout() { this.$store.dispatch('logout'); },
  }
};
</script>

