<template>
  <v-card>
    <v-simple-table>
      <template v-slot:default>
        <tbody>
          <Row :title="$t('fieldName')" :text="field.name" v-if="field.name" />
          <Row :title="$t('fieldName')" :text="$t('notSet')" v-else />

          <Row :title="$t('status')" v-if="field.status">
            <v-btn color="accent" small dense>{{field.status}}</v-btn>
          </Row>

          <Row :title="$t('contractDetails')" v-if="hrContract">
            <Time :time="field.payload['hrContractDetailAt']" v-if="(field.payload || {})['hrContractDetailAt']" />
            <v-btn color="primary" small dense class="ml-2" :to="{name: 'HRContractDetail', query: { uuid: customerUuid, fieldUuid: fieldUuid } }"><v-icon dense>mdi-format-line-style</v-icon> <span class="hidden-xs-only">{{$t('contract')}}</span></v-btn>
          </Row>
          
          <Row :title="$t('score')" v-if="field.score">
            <v-rating
              color="yellow darken-3"
              background-color="grey darken-1"
              empty-icon="$ratingFull"
              medium
              readonly
              :value="Number(field.score)"
            ></v-rating>
          </Row>

          <Row :title="$t('crop')" :text="cropLookups[field.cropId]" />

          <Row :title="$t('size')" >
            <LandSize :landSize="field.fieldSize" v-if="field.fieldSize && field.fieldSize.landSize" />
            <span v-else>{{$t('notSet')}}</span>
          </Row>

          <Row :title="$t('plants')">
            <span v-if="field.numberOfPlants">
              {{ field.numberOfPlants }}
            </span>
            <span v-else>{{$t('notSet')}}</span>
          </Row>

          <Row :title="$t('location')" :location="field.location" />


        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>


<script>

  import Row from '@/components/Row';
  import LandSize from '@/components/LandSize';
  import Time from '@/components/Time';
  
  export default {

    components: { Row, LandSize, Time },

    computed: {
      customerUuid() { return this.$route.query.uuid; },
      fieldUuid() { return this.$route.query.fieldUuid; },
      cropLookups() { return this.$store.getters.cropLookups; },
      partnerCode() { return this.$store.getters.partnerCode; },
      hr() { return ['hr_co', 'winwin_zw'].includes(this.partnerCode); },
      hrContract() { return this.hr; },
    },

    props: ['field'],

  }
</script>


<i18n>
{
  "en": {
    "fieldName": "Field Name",
    "status": "Status",
    "contractDetails": "Contract Details",
    "contract": "Contract",
    "score": "Score",
    "size": "Size",
    "plants": "Plants",
    "notSet": "Not Set"
  },
  "es": {
    "fieldName": "Nombre del campo",
    "status": "Estado",
    "contractDetails": "Detalles del contrato",
    "contract": "Contrato",
    "score": "Puntaje",
    "size": "Tamaño",
    "plants": "Total Plantas",
    "notSet": "No establecido"
  },
  "sw": {
    "fieldName": "Jina la Shamba",
    "status": "Hali",
    "contractDetails": "Maelezo ya Mkataba",
    "contract": "Mkataba",
    "score": "Alama",
    "size": "Ukubwa",
    "plants": "Mimea",
    "notSet": "Haijawekwa"
  }
}
</i18n>
