<template>
  <ApexTz        :customer="customer" @clearInputs="clearInputs" @submitAndEdit="submitAndEdit" @submitAndNew="submitAndNew" v-if="formChoice==='apex_tz'" />
  <HrCo          :customer="customer" @clearInputs="clearInputs" @submitAndEdit="submitAndEdit" @submitAndNew="submitAndNew" v-else-if="formChoice==='hr_co'" />
  <NinayoTz      :customer="customer" @clearInputs="clearInputs" @submitAndEdit="submitAndEdit" @submitAndNew="submitAndNew" v-else-if="formChoice==='ninayo_tz'" />
  <Urban         :customer="customer" @clearInputs="clearInputs" @submitAndEdit="submitAndEdit" @submitAndNew="submitAndNew" v-else-if="formChoice==='urban'" />
  <UzimaUg       :customer="customer" @clearInputs="clearInputs" @submitAndEdit="submitAndEdit" @submitAndNew="submitAndNew" v-else-if="formChoice==='uzima_ug'" />
  <DefaultCreate :customer="customer" @clearInputs="clearInputs" @submitAndEdit="submitAndEdit" @submitAndNew="submitAndNew" v-else />
</template>

<script>
  import { v4 as uuidv4 } from 'uuid';
  import fields from '@/mixins/fields';

  import DefaultCreate from '@/views/contacts/partners/Default';
  import ApexTz from '@/views/contacts/partners/ApexTz';
  import HrCo from '@/views/contacts/partners/HrCo';
  import NinayoTz from '@/views/contacts/partners/NinayoTz';
  import Urban from '@/views/contacts/partners/Urban';
  import UzimaUg from '@/views/contacts/partners/UzimaUg';

  export default {

    components: { DefaultCreate, ApexTz, HrCo, NinayoTz, Urban, UzimaUg },

    data: () => ({
      customer: {
        demographic: { identity: {} },
        farm: {},
      },
    }),

    computed: {
      partnerCode() { return this.$store.getters.settings.partnerCode; },
      formChoice() {
        let f = 'default';
        if (['hr_co', 'winwin_zw'].includes(this.partnerCode)) { f = 'hr_co'; }
        if (['urban_zm', 'urban_ke'].includes(this.partnerCode)) { f = 'urban'; }
        if (this.partnerCode=='apex_tz') { f = 'apex_tz'; }
        if (this.partnerCode=='uzima_ug') { f = 'uzima_ug'; }
        if (this.partnerCode=='ninayo_tz') { f = 'ninayo_tz'; }
        return f;
      },
      crops() { return this.$store.getters.cropNames; },
      depotId() { return this.$store.getters.settings.fieldAgentDepotId; },
      depotName() { return ((this.$store.getters.settings.depots.filter(e => e.id==this.depotId )[0]) || {}).name },
    },

    methods: {
      submitAndNew() {
        this.save();
        this.customer = {};
      },
      submitAndEdit() {
        let uuid = this.save();
        this.customer = {};
        this.$router.push({ name: 'ShowCustomer', query: { uuid: uuid } });
      },
      save() {

        // this.$store.dispatch('logErrorTrace', 'Start contacts.create');

        let customer = this.customer;

        let depotId = ( customer.addToDepot ? this.depotId : null );
        let depot = {
          id: this.depotId,
          name: this.depotName,
        }

        console.log(customer);

        // this.$store.dispatch('logErrorTrace', JSON.stringify(customer) );
        // this.$store.dispatch('logErrorTrace', 'initialised');

        let newCustomer = Object.assign({}, this.customer);
        let demographic = Object.assign({}, (customer.demographic || {}));
        let identity    = Object.assign({}, (demographic.identity || {}));

        console.log(identity);

        // this.$store.dispatch('logErrorTrace', 'cloned');

        newCustomer.uuid = uuidv4();
        newCustomer.isAFarmer = true;
        if (customer.firstName || customer.surname) {
          newCustomer.name = `${(customer.firstName ? customer.firstName : '')} ${(customer.surname ? customer.surname : '')}`.trim();
        }
        newCustomer.savings_group_name = newCustomer.savings_group_name || customer.church;
        newCustomer.fieldAgent = {
          id: this.$store.getters.settings.fieldAgentId,
          name: this.$store.getters.settings.name,
        };
        // this.$store.dispatch('logErrorTrace', 'fa');
        newCustomer.demographic = {
          gender: customer.gender || demographic.gender,
          geographicsArray: customer.regions,
          birthdate: demographic.birthdate,
          dependents: demographic.dependents,
          language: demographic.language,
          maritalStatus: demographic.maritalStatus,
          identity: identity,
        }
        // this.$store.dispatch('logErrorTrace', 'demographic');

        delete(newCustomer.gender);
        delete(newCustomer.church);
        delete(newCustomer.fieldSize);

        // this.$store.dispatch('logErrorTrace', 'customer set');

        if (customer.identity) {
          newCustomer.demographic['identity'] = {
            number: customer.identity.number,
            type: customer.identity.type,
          }
        }

        // this.$store.dispatch('logErrorTrace', 'identity set');

        if (depotId) { newCustomer.depot = depot; }

        // this.$store.dispatch('logErrorTrace', 'depot set');

        if (customer.fieldSize || customer.primaryCrop) {
          let field = this.newField(customer.primaryCrop);
          field.fieldSize = customer.fieldSize || {};
          this.$set(newCustomer, 'fields', [field]);
          if (depotId) { field.depot = depot; }
        }

        // this.$store.dispatch('logErrorTrace', 'crops set');

        // this should move out of here
        if (this.partnerCode=='hr_co' || this.partnerCode=='winwin_zw') { newCustomer.hrContactStatus = 'new'; }

        // this.$store.dispatch('logErrorTrace', JSON.stringify(newCustomer) );
        // this.$store.dispatch('logErrorTrace', 'dispatching');

        this.$store.dispatch('upsertCustomer', newCustomer);

        return newCustomer.uuid;

      },
      
      clearInputs() { this.customer = {}; },
    },

    mixins: [fields],

  }
</script>
