<template>
  <ConfirmationDialog v-model="show">
    <v-card-title>
      Rejesha Mkopo
    </v-card-title>
    <v-card-text>
      Weka kiasi kati ya <Currency showCurrency :value="minPayment" /> na <Currency showCurrency :value="maxPayment" /> ambayo ungependa kulipa

      <v-form v-model="valid">
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="loanAmount" type="number" required prefix="TSh" :rules="[paymentRules]" />
          </v-col>
        </v-row>

      </v-form>

    </v-card-text>
    <v-card-actions>
      <v-btn color="secondary" dense @click="show=false">Ghairi</v-btn>
      <v-spacer />
      <v-btn color="primary" dense type="submit" :disabled="!valid" @click="submit">Rejesha Mkopo</v-btn>
    </v-card-actions>
  </ConfirmationDialog>
</template>


<script>
  import Currency from "@/components/Currency.vue";
  import ConfirmationDialog from "@/components/ConfirmationDialog";

  export default {
    components: { ConfirmationDialog, Currency },
    props: {
       value: Boolean,
       loan: Object,
    },
    data() {
      return {
        loanAmount: 0,
        show: undefined,
        valid: true,
      };
    },
    computed: {
      minPayment() { return ((this.loan || {}).minumum_payment || 0); },
      maxPayment() { return ((this.loan || {}).maximum_payment); },
      amount() { return parseInt(this.loanAmount || 0); },
    },
    watch: { 
      value: {
        immediate: true,
        handler(to,from) { this.show = to; },
      },
      show: {
        immediate: true,
        handler(to,from) {
          this.$emit('input', to);
        },
      },
      loan: {
        immediate: true,
        handler(to) {
          if (to) {
            let l = this.loanAmount || 0;
            let min = to.minumum_payment || 0;
            let max = to.maximum_payment;
            if (l<min) { l = min; }
            if ((max) && (l>max)) { l = to.maximum_payment; }
            this.loanAmount = l;
          }
        },
      },
    },
    methods: {
      submit() {
        this.show = false;
        this.$emit('alert', "Mchakato wa ulipaji umeanzishwa. Baada ya muda mfupi utapokea dirisha ibukizi kwenye simu yako ya mkononi ili kuidhinisha malipo.");
        console.log('save');
      },
      paymentRules(v) {
        let r = 'Inahitajika';
        if (!!v) {
          r = true;
          if (this.amount < this.minPayment) { r = `Lazima iwe zaidi ya ${this.minPayment}`; }
          if (this.amount > this.maxPayment) { r = `Lazima iwe chini ya ${this.maxPayment}`; }
        }
        return r;
      },
    },
  }
</script>