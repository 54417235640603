<template>
  <ServerSideResult :test="customer" :size="80">

    <v-container pa-1 v-if="customer">
      <v-row>

        <v-col xs="12" sm="6">
          <Profile :customer="customer" @requestLoan="requestLoan" @pleaseCallMe="pleaseCallMe" />
        </v-col>

        <v-col xs="12" sm="6" v-if="alert">
          <v-alert
            v-model="alert"
            border="left"
            color="success"
            dark
            dismissible
            class="text-left"
          >
            {{message}}
          </v-alert>
        </v-col>

        <v-col xs="12" sm="6" v-for="loan in customer.products" :key="loan.id" >
          <LoanCard :loan="loan" @settle="settle" @rollover="rollover" @repay="repay" />
        </v-col>

        <v-col sm="12" class="d-flex justify-center" v-if="!areProductsPresent">
          <Logo :maxWidth="250" :maxHeight="250" />
        </v-col>

      </v-row>

      <ConfirmPleaseCallMe v-model="confirmPleaseCallMe" :customer="customer" @alert="alertNotification" />
      <ConfirmRequestLoan v-model="confirmRequestLoan" :customer="customer" @alert="alertNotification" @requestLoan="requestLoan"/>
      <ConfirmSettleLoan v-model="confirmSettleLoan" :loan="loan" @alert="alertNotification" />
      <ConfirmRolloverLoan v-model="confirmRolloverLoan" :loan="loan" @alert="alertNotification" />
      <ConfirmRepayLoan v-model="confirmRepayLoan" :loan="loan" @alert="alertNotification" />

    </v-container>

  </ServerSideResult>
</template>

<style scoped></style>

<script>
import lookups from "@/mixins/lookups";
import Profile from "@/views/partners/ultimate_tz/Profile.vue";
import LoanCard from "@/views/partners/ultimate_tz/LoanCard.vue";
import ServerSideResult from "@/components/ServerSideResult";
import ConfirmPleaseCallMe from "@/views/partners/ultimate_tz/ConfirmPleaseCallMe";
import ConfirmRequestLoan from "@/views/partners/ultimate_tz/ConfirmRequestLoan";
import ConfirmSettleLoan from "@/views/partners/ultimate_tz/ConfirmSettleLoan";
import ConfirmRolloverLoan from "@/views/partners/ultimate_tz/ConfirmRolloverLoan";
import ConfirmRepayLoan from "@/views/partners/ultimate_tz/ConfirmRepayLoan";
import Logo from "@/components/Logo";

export default {

  components: { Profile, LoanCard, ServerSideResult, Logo, ConfirmPleaseCallMe, ConfirmRequestLoan, ConfirmSettleLoan, ConfirmRolloverLoan, ConfirmRepayLoan },

  data() {
    return {
      customer: undefined,
      loan: undefined,
      alert: false,
      message: undefined,
      confirmPleaseCallMe: false,
      confirmRequestLoan: false,
      confirmSettleLoan: false,
      confirmRolloverLoan: false,
      confirmRepayLoan: false,
    };
  },

  computed: {
    areProductsPresent() {
      return this.customer && this.customer.products.length > 0;
    },
  },

  methods: {
    requestLoan(customer) { 
      this.confirmRequestLoan = true;
      if (customer) { console.log(customer)};
    },
    pleaseCallMe(customer) { this.confirmPleaseCallMe = true },
    settle(loan) { this.loan = loan; this.confirmSettleLoan = true },
    rollover(loan) { this.loan = loan; this.confirmRolloverLoan = true },
    repay(loan) { this.loan = loan; this.confirmRepayLoan = true },
    alertNotification(message) {
      this.message = message;
      this.alert = true;
    }
  },

  mounted() {
    this.remoteLookup("ultimateTzInitialise").then(
      (data) => (this.customer = data)
    );
  },

  mixins: [lookups],

};
</script>
