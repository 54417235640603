<template>
  <HeaderBarUltimateTz v-if="partnerCode==='ultimate_tz'" />
  <HeaderBarDefault :displaySearchOnRoutes="displaySearchOnRoutes" v-else />
</template>

<script>

  import HeaderBarDefault from '@/components/HeaderBarDefault';
  import HeaderBarUltimateTz from '@/components/HeaderBarUltimateTz';

  export default {
    components: { HeaderBarDefault, HeaderBarUltimateTz },
    props: ['displaySearchOnRoutes'],
    computed: {
      partnerCode() { return this.$store.getters.settings.partnerCode; },
    },
  };

</script>

