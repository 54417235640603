<template>
  <UzimaUg v-if="partnerCode==='uzima_ug'" />
  <UltimateTz v-else-if="partnerCode === 'ultimate_tz'" />
  <Default v-else />
</template>

<script>
import Default from '@/views/login/partners/Default';
import UzimaUg from '@/views/login/partners/UzimaUg';
import UltimateTz from "@/views/login/partners/UltimateTz";

export default {
  components: { Default, UzimaUg, UltimateTz },
  computed: {
    partnerCode() { return this.$store.getters.settings.partnerCode; },
  },
  mounted() { this.$i18n.locale = this.language; }
};
</script>
