<template>
  <v-form v-model="validForm" ref="contactForm">
    <v-container>

      <v-row class="blue lighten-5">
        <v-col cols="12" sm="6">
          <MsisdnInput v-model="customer.msisdn" />
        </v-col>
      </v-row>

      <v-row class="blue lighten-5">
        <v-col cols="12">
          <NameInput v-model="customer.name" :label="$t('Name')" />
        </v-col>
      </v-row>

      <v-row class="yellow lighten-5">

        <v-col cols="12" sm="6" md="4" lg="3">
          <v-checkbox
            v-model="customer.isAnUzimaVillageAmbassador"
            :label="$t(vaLabel)"
            @change="updateVA"
          ></v-checkbox>
        </v-col>

        <v-col cols="12" sm="6" md="4" lg="3">
          <v-checkbox
            v-model="customer.isAnUzimaAgent"
            :label="$t(agentLabel)"
            @change="updateAgent"
          ></v-checkbox>
        </v-col>

      </v-row>

      <v-row class="red lighten-5">

        <v-col cols="12" sm="6" md="4" lg="3">
            <v-text-field
              v-model="customer.age"
              :label="$t('age')"
              :hint="$t('ageHint')"
              persistent-hint
              min=0
              max=100
              type='number'
            />
        </v-col>

        <v-col cols="12" sm="6" md="4" lg="3">
          <GenderInput v-model="customer.demographic.gender" />
        </v-col>


        <v-col cols="12" sm="6" md="4" lg="3">
          <v-combobox
            v-model="customer.uzimaSubcounty"
            :items="subcounties"
            :label="$t('subcounties')"
            :hint="$t('subcounties')"
            single-line
            @change="setSubcounty"
          ></v-combobox >
        </v-col>

        <v-col cols="12" sm="6" md="4" lg="3">
          <v-combobox v-model="customer.savings_group_name" :label="$t('parish')" :hint="$t('parish')" :items="parishes" single-line></v-combobox>
        </v-col>

        <v-col cols="12" sm="6" md="4" lg="3">
          <v-select
            :label="$t('profession')"
            :items="professions"
            v-model="customer.primaryIncome"
          ></v-select>
        </v-col>

        <v-col cols="12" sm="6" md="4" lg="3">
          <v-select
            :label="$t('transport')"
            :items="vehicles"
            v-model="customer.transport"
          ></v-select>
        </v-col>

        <v-col cols="12" sm="6" md="4" lg="3">
          <v-select
            :label="$t('source')"
            :items="sources"
            v-model="customer.leadSource"
          ></v-select>
        </v-col>

      </v-row>


      <v-row class="blue-grey lighten-5">
        <v-col cols="12">
          <v-textarea
            :label="$t('Notes')"
            v-model="customer.note"
            auto-grow
          ></v-textarea>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <div class="d-flex justify-space-between">
            <div class="text-left">
              <v-btn @click="$emit('clearInputs')" class="d-none d-sm-inline">{{$t('Clear')}}</v-btn>
            </div>
            <div class="text-right">
              <v-btn @click="$emit('submitAndEdit')" color="primary" :disabled="!validForm" class="mr-2">{{$t('SaveAndEdit')}}</v-btn>
              <v-btn @click="$emit('submitAndNew')" color="primary" :disabled="!validForm">{{$t('SaveAndAdd')}}</v-btn>
            </div>
          </div>
        </v-col>
      </v-row>

    </v-container>
  </v-form>
</template>


<script>
  import uzima from '@/mixins/uzimaUg';

  import RegionPicker from '@/components/RegionPicker';
  import MsisdnInput from '@/components/MsisdnInput';
  import EmailInput from '@/components/EmailInput';
  import NameInput from '@/components/NameInput';
  import DateInput from '@/components/DateInput';
  import GenderInput from '@/components/GenderInput';

  export default {

    components: {
      RegionPicker,
      MsisdnInput,
      EmailInput,
      NameInput,
      DateInput,
      GenderInput,
    },

    props: ['customer'],

    data: () => ({
      validForm: false,
      agentLabel: 'isNotAnAgent',
      vaLabel: 'isAVA',
    }),

    mounted() {
      this.customer.isAnUzimaVillageAmbassador = true;
      this.customer.isAnUzimaAgent = false;
      this.customer.regions = this.$store.getters.settings.fieldAgentGeographics;
    },

    methods: {
      updateAgent() { this.agentLabel = (this.customer.isAnUzimaAgent ? 'isAnAgent' : 'isNotAnAgent'); },
      updateVA() {    this.vaLabel    = (this.customer.isAnUzimaVillageAmbassador ? 'isAVA' : 'isNotAVA'); },
      setSubcounty() { this.customer.regions[3] = this.customer.uzimaSubcounty; }
    },

    mixins: [uzima],

  }

</script>


<i18n>
{
  "en": {
    "Primary Crop": "Primary Crop",
    "Name": "Name",
    "Notes": "Notes",
    "SaveAndEdit": "Save & Edit",
    "SaveAndAdd": "Save & Add",
    "Clear": "Clear",
    "subcounties": "Subcounty",
    "parish": "Parish",
    "source": "Source",
    "profession": "Current, Primary Income",
    "age": "Age",
    "ageHint": "If unsure, estimate",
    "transport": "Transport",
    "isAnAgent": "Is also an Agent",
    "isNotAnAgent": "Is NOT an Agent",
    "isAVA": "Is a village ambassador",
    "isNotAVA": "Is NOT a Village Ambassador"
  },
  "es": {
    "Primary Crop": "Variedad",
    "Name": "Nombre",
    "Notes": "Notas",
    "SaveAndEdit": "Salvar o Editar",
    "SaveAndAdd": "Salvar y añadir (Otro Contacto)",
    "Clear": "Reiniciar",
    "subcounties": "Subcounty",
    "parish": "Parish",
    "source": "Source",
    "profession": "Renta primaria actual",
    "age": "Age",
    "ageHint": "Si no está seguro, estime",
    "transport": "Transport",
    "isAnAgent": "Is an Agent",
    "isNotAnAgent": "Is NOT an Agent"
  },
  "sw": {
    "Primary Crop": "Mazao ya Msingi",
    "Name": "Jina",
    "Notes": "Noti",
    "SaveAndEdit": "Hifadhi na hariri",
    "SaveAndAdd": "Hifadhi na sajili mwingine",
    "Clear": "Futa",
    "subcounties": "Subcounty",
    "parish": "Parish",
    "source": "Source",
    "profession": "Mapato ya sasa",
    "age": "Age",
    "ageHint": "Kama huna uhakika, kadiria",
    "transport": "Transport",
    "isAnAgent": "Is an Agent",
    "isNotAnAgent": "Is NOT an Agent"
  }
}
</i18n>
