<template>
  <v-col cols="12" sm="6" lg="4" class="pa-1" v-if="show">
    <v-card>
      <v-card-title>
        Hugo Restrepo Ocupaciones
      </v-card-title>

      <v-card-actions>
        <v-btn color="success" small dense :to="{name: 'HRFirstVisit', query: { uuid: customer.uuid }}">
          <v-icon dense>mdi-star-check-outline</v-icon>
          <span>Primera Visita</span>
        </v-btn>
      </v-card-actions>

    </v-card>
  </v-col>
</template>

<script>
  export default {
    props: ['customer'],

    computed: {
      partnerCode() { return this.$store.getters.settings.partnerCode; },
      show() {
        let r = false;
        if (this.partnerCode==='hr_co') {
          r = true;
        }
        return r;
      }
    },

  }
</script>
