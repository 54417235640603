<template>
  <PageHeader title="First Visit" :subtitle="customer.name" icon="mdi-flower-outline" @back="toCustomer" @save="submit" back save :valid='formValid' >

    <v-form>

      <FormContainer color="blue lighten-5">

        <v-container fluid class="py-0 px-2">
          <v-row>
            <v-col cols="12" sm="4"><StarRatingCard v-model="assessment['ubicacionTerreno']"          title="Ubicación terreno"           description="TBD" /></v-col>
            <v-col cols="12" sm="4"><StarRatingCard v-model="assessment['alturaSobreNivelDelMar']"    title="Altura sobre nivel del mar"  description="TBD" /></v-col>
            <v-col cols="12" sm="4"><StarRatingCard v-model="assessment['zonaSeca']"                  title="Zona seca"                   description="TBD" /></v-col>
            <v-col cols="12" sm="4"><StarRatingCard v-model="assessment['disponibilidadDeAguas']"     title="Disponibilidad de aguas"     description="TBD" /></v-col>
            <v-col cols="12" sm="4"><StarRatingCard v-model="assessment['analisisDeSuelos']"          title="Análisis de suelos"          description="TBD" /></v-col>
            <v-col cols="12" sm="4"><StarRatingCard v-model="assessment['tierrasPropias']"            title="Tierras propias"             description="TBD" /></v-col>
            <v-col cols="12" sm="4"><StarRatingCard v-model="assessment['riegosPorGoteo']"            title="Riegos por goteo"            description="TBD" /></v-col>
            <v-col cols="12" sm="4"><StarRatingCard v-model="assessment['manoDeObraParaCosecha']"     title="Mano de obra para cosecha"   description="TBD" /></v-col>
            <v-col cols="12" sm="4"><StarRatingCard v-model="assessment['agricultorConExperiencia']"  title="Agricultor con experiencia"  description="TBD" /></v-col>
            <v-col cols="12" sm="4"><StarRatingCard v-model="assessment['recursosEconomicos']"        title="Recursos económicos"         description="TBD" /></v-col>


            <v-col cols="12">
              <v-textarea label="Observaciones" v-model="assessment.Observations" auto-grow ></v-textarea>
            </v-col>
            
          </v-row>
        </v-container>

      </FormContainer>
    </v-form>

  </PageHeader>
</template>

<script>
  import { v4 as uuidv4 } from 'uuid';

  import PageHeader from '@/components/PageHeader';
  import FormContainer from '@/components/FormContainer';
  import StarRatingCard from '@/components/StarRatingCard';

  export default {
    components: { PageHeader, FormContainer, StarRatingCard },
    data: () => ({
      assessment: {
        uuid: uuidv4(),
        Observations: ''
      },
    }),
    computed: {
      customerUuid() { return this.$route.query.uuid; },
      fieldUuid() { return this.$route.query.fieldUuid; },
      customer() { return this.$store.getters.customer(this.customerUuid); },
      // field() { return this.customer.fields.filter( f => f.uuid==this.fieldUuid )[0]; },
      formValid() { return Object.keys(this.assessment).length==12 }, // we already have two elements in the set: Observations and uuid
    },

    methods: {

      submit() {

        let score = 0;
        const results = this.assessment;


        score = score + results['ubicacionTerreno'] *0.13;
        score = score + results['alturaSobreNivelDelMar'] *0.08;
        score = score + results['zonaSeca'] *0.1;
        score = score + results['disponibilidadDeAguas'] *0.12;
        score = score + results['analisisDeSuelos'] *0.12;
        score = score + results['tierrasPropias'] *0.05;
        score = score + results['riegosPorGoteo'] *0.13;
        score = score + results['manoDeObraParaCosecha'] *0.09;
        score = score + results['agricultorConExperiencia'] *0.09;
        score = score + results['recursosEconomicos'] *0.09;

        score = Math.round(score);
        // this.field.score = score;
        // results['_score'] = score;

        // this.field.assessmentScores = this.field.assessmentScores || [];
        // this.field.assessmentScores.push(results);

        // if (this.field.status==='new') { this.field.status = 'assessed'; }
        // this.field.scoredAt = new Date().toISOString();

        // this.$store.dispatch('upsertCustomer', this.customer);
        
        // this.toField();

      },

      toCustomer() { this.$router.push({ name: 'ShowCustomer', query: { uuid: this.uuid } }); },

      // toField() { this.$router.push({name: 'Field', query: { uuid: this.customerUuid, fieldUuid: this.field.uuid }}); },
      
    }
  }
  
</script>