<template>
  <v-card>
    <v-card-title>
      {{$t('title')}}
      <v-spacer />
      <v-btn color="primary" small dense :to="{name: 'VaOrder', query: { uuid: customer.uuid } }">{{$t('new')}}</v-btn>
    </v-card-title>

    <v-card-text>
      <v-simple-table v-if="deliveries.length>0">
        <tbody>
          <tr v-for="delivery in deliveries">
            <td class="text-left"><Time :time="delivery.createdAt" format="date" /></td>
            <td class="text-right"><NumberFormatter :value="delivery.quantity" /> moc</td>
            <td class="hidden-xs-only">
              <v-btn color="primary" text :to="{name: 'ShowDelivery', query: { uuid: delivery.customerUuid, deliveryUuid: delivery.uuid }}" class="ml-1">
                <v-icon dense>mdi-truck-delivery-outline</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <div v-else>
        {{$t('noDeliveries')}}
      </div>
    </v-card-text>

  </v-card>
</template>


<script>
  import fields from '@/mixins/fields';

  import NumberFormatter from '@/components/NumberFormatter';
  import Time from '@/components/Time';

  export default {

    components: { NumberFormatter, Time },

    props: ['customer'],

    computed: {
      customerUuid() { return this.$route.query.uuid; },
      deliveries() { return this.$store.getters.deliveriesForDeliveredTo(this.customerUuid).reverse().slice(0,5); },
    },

  }
</script>


<i18n>
{
  "en": {
    "title": "MOC Deliveries",
    "noDeliveries": "No deliveries yet."
  }
}
</i18n>
