<template>
  <v-card class="text-left">

    <v-list-item three-line>
      <v-list-item-content>
        <v-list-item-title class="text-h5 mb-1 text-capitalize">
         {{ name }}
        </v-list-item-title>
      </v-list-item-content>
      <v-list-item-avatar tile size="40" >
        <img
          alt="user"
          src="@/assets/ultimate-finance-logo.jpeg"
          >
      </v-list-item-avatar>
    </v-list-item>

    <v-list-item class="pb-2" v-if="!customer.is_ultimate">
      <v-list-item-content>
        Karibu Ultimate Finance.
      </v-list-item-content>
    </v-list-item>

    <v-card-actions class="mt-n4">
      <v-btn raised small color="primary" @click="$emit('requestLoan', customer)">
        Omba Mkopo
      </v-btn>
      <v-btn raised small color="primary" @click="$emit('pleaseCallMe', customer)">
        Tafadhali nipigie
      </v-btn>
    </v-card-actions>

  </v-card>
</template>

<script>
export default {
  props: ['customer'],
  computed: {
    name() { return (this.customer.first_name || 'Hello!').toLowerCase(); },
  }
};
</script>
