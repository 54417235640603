<template>
  <span>
    <small v-if="displayCurrency">{{currency}}</small>
    <NumberFormatter :value="value" />
  </span>
</template>



<script>
  import NumberFormatter from '@/components/NumberFormatter';

  export default {

    components: { NumberFormatter },

    props: {
      value: Number,
      showCurrency: Boolean
    },

    computed: {
      currency() { return this.$store.getters.currency; },
      displayCurrency() { return (this.showCurrency || this.$vuetify.breakpoint.name!=='xs'); }
    },

  }
</script>
